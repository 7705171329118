import {initLaureat} from './laureat.js'

export function initArchivesLaureates() {

	console.log('Archive Laureates')

       $('#search_form').submit(function(e) {
           e.preventDefault();

           var form = $(this);
           console.log(form.serializeArray());
           $.ajax({
               method: "POST",
               url : form.data("ajaxurl"),
               data : form.serialize(),
               success : function (data) {
                   data = JSON.parse(data);
                   $('#filter_content').html(data.html);
                   $(".laureats__list .results").text(data.count+" résultat(s)");

                   orderResults();
               },
               error : function () {
                   alert("error")
               }
           });
       });

       $(".custom-select").change(function () {
           $('#search_form').submit();
       })

       var timerChange;
       $("input[name=search]").keyup(function () {
           clearTimeout(timerChange);
           timerChange = setTimeout(function () {
               $("form.filters").submit();
           }, 500)
       })

       $("input[name=orderby], #asc").click(function () {
           orderResults();
       })

	   const laureateURL = window.location.href;
	   $('.laureats__list').magnificPopup({
	   		delegate: 'a.laureat__preview',
	   	    type: 'ajax',
	   	    removalDelay: 800,
	   	    mainClass: 'mfp-animate',
	   	    closeBtnInside:true,
	   	    fixedContentPos:true,
	   	    closeMarkup :
	   	    `<button title="%title%" type="button" class="mfp-close">
	   	      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28.14 28.14">
	   		  	<polygon points="28.14 2.8 25.34 0 14.07 11.27 2.8 0 0 2.8 11.27 14.07 0 25.34 2.8 28.14 14.07 16.87 25.34 28.14 28.14 25.34 16.87 14.07 28.14 2.8"/>
	   		  </svg>
	   	    </button`,
	   	    callbacks: {
		   	      parseAjax: function(mfpResponse) {
		   	        mfpResponse.data = $(mfpResponse.data).find('.ajaxcontent');
		   	      },
		   	      ajaxContentAdded: function() {
	   					history.pushState('', '', this.currItem.src);
	   	        initLaureat();
	   		  },
	   		  afterClose: function() {
	   			  history.pushState('', '', laureateURL);
	   		  }
	   	    }
	   	 });

	   function orderResults() {

	       var value = $("input[name='orderby']:checked").val();
	       var asc = $("#asc").is(":checked");
	       var ordered = $('.bloc_laureat').sort(function (a, b) {
	           var contentA = $(a).data(value);
	           var contentB = $(b).data(value);
	           if (asc){
	               return (contentA > contentB) ? -1 : (contentA < contentB) ? 1 : 0;
	           } else {
	               return (contentA < contentB) ? -1 : (contentA > contentB) ? 1 : 0;
	           }
	       })

	       $("#filter_content").html(ordered)
	   }

		 orderResults();

}
