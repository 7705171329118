export function initArchivesActus() {

	console.log('Archive Actus');

	const news_container = document.getElementById('news_container');
	const load_more = document.getElementById('load_more');
	const fetch_url = load_more.dataset.fetch;

	let currentPage = 1;

	var fetchNews = () => {
		const xhr = new XMLHttpRequest;
		fetch(`${fetch_url}&page=${currentPage}`)
			.then(res => res.json())
			.then(res => {
				console.log(res);
				news_container.innerHTML += res.html;
				if (res.left){
					load_more.style.display = 'inline-block';
					currentPage++
				} else {
					load_more.style.display = 'none';
				}
			})
	};

	fetchNews();
	load_more.addEventListener('click', () => fetchNews())

}
