export function initAccompagnateurs() {

	console.log('Nos Accompagnateurs');

	var timerChange;

	$("#inlineFormInput").keydown(function () {
        clearTimeout(timerChange);
        timerChange = setTimeout(function () {
            $("form.filters").submit();
        }, 500)
    })

	$("form.filters").submit(function (e) {
	    e.preventDefault();

		var form=$(this);
        $.ajax({
            method: "POST",
            url : form.data("ajaxurl"),
            data : form.serializeArray(),
            success : function (data) {
                data = JSON.parse(data);
                $('#filter_content').html(data.html);
                $(".accompanists__list .results").text(data.count+" résultat(s)")
            },
            error : function () {
                alert("error");
            }
        })
    })


}
