import {initHomepage} from './pages/home.js'
import {initLaureat} from './pages/laureat.js'
import {initAccompagnateurs} from './pages/nos_accompagnateurs.js'
import {initArchivesLaureates} from './pages/archive-laureate.js'
import {initArchivesActus} from './pages/archive-actus.js'


const $body = $('body');

class App {

  constructor(opt = {}) {

    window.scrollTo(0,0)
    console.log("%cCreated by wokine","color: #000; padding: 5px 0px;"),console.log("%chttp://wokine.com","color:#ccc")

    this.init()
	  this.setRoutes()
    this.addEvents()
    this.intro();

  }

  init() {

  	$('.navbar-toggler').on('click', (e)=> {
  		e.stopPropagation();
  		$body.toggleClass('showMenu');
  	})

  	$('.mobile__fade').on('click', (e)=> {
  		e.preventDefault();
  		$body.removeClass('showMenu');
  	})


  	inView('.reveal').on('enter', e => {
  		if ($(e).hasClass('in-view')) return;
  		$(e).addClass('in-view');
  	})

	//partenaires
  	if ($('.swiper__partners').length > 0 ) {
  		  const swiperPartnersContainer = $('.swiper__partners');
  		  const swiperPartnersPrev = $('.swiper__partners .swiper-button-prev');
  		  const swiperPartnersNext = $('.swiper__partners .swiper-button-next');
  		  const partnerSwiper = new Swiper(swiperPartnersContainer.find('.swiper-container'), {
  			  speed: 800,
  			  spaceBetween: 60,
  			  loop:true,
  			  autoplay:3000,
  			  centeredSlides:true,
  			  slidesPerView:'auto',
  			  nextButton: swiperPartnersNext,
  			  prevButton: swiperPartnersPrev,
  		  });
  	}

    //PLAYERS
  	if ($('.video__container').length > 0 ) {

  		const $videoPlyrContainer = $('.video__container');
  	    $videoPlyrContainer.each(function(index, elem){

  			let videoPlyr = plyr.setup(elem, {
  				controls:['progress'],
  			});

  			$(elem).find('.play').on('click', function(e) {
  				e.preventDefault();
  				videoPlyr[0].play();
  			});

  			$(elem).find('.poster').on('click', function(e) {
  				e.preventDefault();
  				videoPlyr[0].play();
  			});

  			$(elem).find('.plyr__video--wrapper').on('click', function(e) {
  				e.preventDefault();
  				videoPlyr[0].pause();
  			});

  			videoPlyr[0].on('play', function(event) {
  				$(elem).addClass("playing");
  			});

  			videoPlyr[0].on('pause', function(event) {
  				$(elem).removeClass("playing");
  			});
  		});

  	}

  	//chiffres
  	if($('.chiffres__list').length > 0 ) {

  		let b = baffle('.chiffre', {
  			characters: '1234567890',
  			speed: 150
  		});

  		inView('.chiffres__list')
  	    .on('enter', e => {
  		//	if (!$(e).hasClass('in-view')) {

  				$(e).addClass('in-view');
  				b.start().reveal(1000, 1000);
  		//	}
  		}).on('exit', e => {
  			$(e).removeClass('in-view');
  		})

  	}


    if($('.rects').length > 0 ) {
      $('.page__intro').on('mousemove', (e)=>{

        let xMouse = e.pageX - e.currentTarget.getBoundingClientRect().left - ( e.currentTarget.offsetWidth / 2 );
  		  let yMouse = e.pageY - window.pageYOffset - e.currentTarget.getBoundingClientRect().top - ( e.currentTarget.offsetHeight / 2 );
        TweenMax.to(".page__title .rect", 1.2, {x:xMouse * 0.05, y:yMouse * 0.05, ease:Power3.easeOut});
        TweenMax.to(".page__intro .rects .wrapper", 1.2, {x:xMouse * 0.03, y:yMouse * 0.03, ease:Power3.easeOut});

      });
    }

    }

  setRoutes() {

	  if ($body.hasClass('home')) initHomepage();
	  if ($body.hasClass('single-lmi_laureate')) initLaureat();
	  if ($body.hasClass('page-template-archive-lmi_accompanist')) initAccompagnateurs();
	  if ($body.hasClass('post-type-archive-lmi_laureate')) initArchivesLaureates();
	  if ($body.hasClass('post-type-archive-lmi_news')) initArchivesActus();
	  //if ($body.hasClass('page-template-page-contact')) initContact();

  }

  addEvents() {

    //window.onbeforeunload = function() {window.scrollTo(0,0);}

  }

  intro() {


      //removeLoading
      if (!$body.hasClass('home')) {
        $('body').removeClass('is-loading');
        return;
      }

      const intro = new TimelineMax({
         paused: true,
         onStart: () => {
           $('body').addClass('is-intro');
           $('body').removeClass('is-loading');
         },
         onComplete: () => {
           $('body').removeClass('is-intro');
         }
      })

      intro.from('.loader__container .inner', 1.2, { scale:30, rotation:-100, ease: Power3.easeOut }, 0);
      intro.to('.loader__container .inner', 1.2, { y: "-80%", ease: Power3.easeInOut}, 1.6);
      intro.to('.loader__container', 1.2, { y: "100%", ease: Power3.easeInOut}, 1.6);
      intro.from('.app', 1.2, { y: -40, ease: Power3.easeInOut}, 1.6);
      intro.play();


  }

}

const app = new App();
