export function initLaureat() {

	console.log('Single Laureat');

	if ($('.map__container').length > 0 ) {

		const $map = $('#map');
		const lat = $map.data('lat');
		const lng = $map.data('lng');
		const laureatLatLng = {lat: lat, lng: lng};

		const laureatMap = new google.maps.Map(document.getElementById('map'), {
	      center: laureatLatLng,
	      zoom: 16,
		  disableDefaultUI: true
	    });

		const laureatMarker = new google.maps.Marker({
	      position: laureatLatLng,
	      map: laureatMap
	    });

	}



}
